import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateMedia } from '../../../../../api/app/media'
import { useMediaItemContext } from '../../../../contexts/MediaItemProvider'

const useMuteBackgroundMusic = () => {
	/** Retrieve dispatch method */
	const dispatch = useDispatch()

	/** Retrieve item from the context */
	const { item } = useMediaItemContext()
	/** Manage Background Music State */
	const [muteBackgroundMusic, setMuteBackgroundMusic] = useState(item.muteBackgroundMusic)

	/** Update background mute when item change */
	useEffect(() => {
		setMuteBackgroundMusic(item.muteBackgroundMusic)
	}, [item])

	/** Callback handler to update the background music status */
	const handleMuteBackgroundMusic = useCallback(() => {
		/** Clone data */
		let newData = {
			id: item.id,
			mediaUuid: item.mediaUuid,
		}
		/** Set new state */
		newData.muteBackgroundMusic = !muteBackgroundMusic
		newData.socketReloadPage = true
		/** Update media */
		dispatch(updateMedia(newData, false))
		/** Update state */
		setMuteBackgroundMusic(!muteBackgroundMusic)
	}, [muteBackgroundMusic, item])

	return [muteBackgroundMusic, handleMuteBackgroundMusic]
}

export default useMuteBackgroundMusic
