import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Box, Flex, Textarea, keyframes, Text } from '@chakra-ui/react'

/**
 * Define Special keys to check against when prohibiting additional char entry.
 */
let keys = {
	backspace: 8,
	tab: 9,
	shift: 16,
	ctrl: 17,
	command: 91,
	alt: 18,
	delete: 46,
	// 'cmd':
	leftArrow: 37,
	upArrow: 38,
	rightArrow: 39,
	downArrow: 40,
}

/**
 * Define utils methods
 */
let utils = {
	special: {},
	navigational: {},
	isSpecial(e) {
		return e && typeof this.special[e.keyCode] !== 'undefined'
	},
	isNavigational(e) {
		return e && typeof this.navigational[e.keyCode] !== 'undefined'
	},
}
/** Extend with special keys */
utils.special[keys['backspace']] = true
utils.special[keys['tab']] = true
utils.special[keys['shift']] = true
utils.special[keys['ctrl']] = true
utils.special[keys['command']] = true
utils.special[keys['alt']] = true
utils.special[keys['delete']] = true
/** Extend with navigational keys */
utils.navigational[keys['upArrow']] = true
utils.navigational[keys['downArrow']] = true
utils.navigational[keys['leftArrow']] = true
utils.navigational[keys['rightArrow']] = true

const warnRedText = keyframes`
	0% {
		color: #ff1c63;
	}
	100% {
		color: inherit;
	}
`

const TextAreaForm = ({ data, focused, maxChars, restricted = true, cols, rows, onChange, onFocus, placeholder }) => {
	const dispatch = useDispatch()
	/** Define ref for the textarea input */
	const textAreaRef = useRef()
	/** Is the input focused */
	const [isFocused, setIsFocused] = useState(focused)
	/** How many characters are we over by ? */
	const [overCharLimit, setOverCharLimit] = useState(false)
	/** Set the current count of characters */
	const [charCount, setCharCount] = useState(0)
	/** How many lines should the text be split by */
	const [currentLines, setCurrentLines] = useState(1)
	/** Set initial text content used by the textarea, with default to the prop passed. */
	const [value, setValue] = useState(data.text)
	/**
	 * Helper method to format text according to given lines
	 * @param {string} val
	 */
	const getLines = (val) => {
		// Enforce per line limits (dynamically add newline character)
		let lines = val.split('\n')

		for (var i = 0; i < lines.length; i++) {
			if (lines[i].length <= cols) continue
			var j = 0
			var space = cols
			while (j++ <= cols) {
				if (lines[i].charAt(j) === ' ') space = j
			}
			lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || '')
			lines[i] = lines[i].substring(0, space)
		}
		return lines
	}

	/**
	 * Helper function to format the text
	 * @param {string} val
	 */
	const formatString = (val) => {
		/** Format string */
		let result

		// set textarea value with trimmed to max chars setting
		result = val
			.toString()
			.substring(0, maxChars)
			// Glyphs and Emoji
			.replace(
				/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|[\u2694-\u2697]|[\u2580-\u27BF]|\u2764\uFE0F|\uD83E[\uDD10-\uDDFF]|\uD83E[\uDD10-\uDD5D]|\uD83E[\uDD10-\uDDFF])/gm,
				''
			)
			// Curly Brackets
			.replace(/[\u2018\u2019]/g, "'")
			.replace(/[\u201C\u201D]/g, '"')

		return result
	}

	/** Set textarea value received from props */
	useEffect(() => {
		if (data.text) {
			/** Save final value */
			setValue(data.text) //setValue(text)
		}
	}, [data.text])

	/** Format text on initial mount,and handle initial focus */
	useEffect(() => {
		handleTextChange()
		if (value == '' || focused) {
			setTimeout(() => {
				textAreaRef.current?.focus()
			}, 500)
		}
	}, [])

	/** Handler when on Focus */
	const handleFocus = (e) => {
		setIsFocused(true)
		if (onFocus) onFocus(true)
	}

	/** Handler when on Blur */
	const handleBlur = (e) => {
		setIsFocused(false)
		if (onFocus) onFocus(false)
	}

	const handleTextChange = (e) => {
		/**
		 * Prevent save modal from popping up if we're
		 * using the nav keys from within the textcard textarea
		 * */
		if (e?.keyCode && (e?.keyCode == 37 || e?.keyCode == 38 || e?.keyCode == 39 || e?.keyCode == 40)) {
			e.stopPropagation()
		}

		const textbox = textAreaRef.current
		const textLength = textbox.value.length
		let textContent = textbox.value

		// Format thins for TextCard
		// Enforce per line limits (dynamically add newline character)
		let lines = getLines(textContent)

		if (restricted) {
			// Line/Row count
			textContent = lines.slice(0, 6).join('\n')
		}

		// set textarea value with trimmed to max chars setting
		textbox.value = formatString(textContent)

		let hasSelection = false
		let selection = window.getSelection()
		let isSpecial = utils.isSpecial(e)
		let isNavigational = utils.isNavigational(e)

		if (selection) {
			hasSelection = !!selection.toString()
		}

		/// Allow or deny additional chars entry if NOT special or navigational key
		if ((textLength > maxChars || lines.length > rows) && !hasSelection) {
			if (isSpecial || isNavigational) {
			} else {
				setOverCharLimit(true)
				setTimeout(() => {
					setOverCharLimit(false)
				}, 300)

				if (e) {
					e.preventDefault()
					return false
				}
			}
		}

		/** Update current char count */
		setCharCount(escape(textLength))
		/** Set new value */
		setValue(textContent)
		/** Set char character limit */
		setOverCharLimit(false)
		/** Set current Lines */
		setCurrentLines(lines.length)
		/** Build a new object based on initial data with new text content */
		const newMedia = { ...data, text: textContent }
		/** Pass to the parent the new value */
		onChange(newMedia)
	}

	/** Format the char limit */
	var charLimits = `${charCount}`

	return (
		<Box w="full">
			<Box h={['270px', '360px', '480px']} mb="30px" bg="gray.100" maxW="800px" mx="auto" position="relative">
				<Flex
					h="full"
					w="auto"
					m="auto"
					border="none"
					resize="none"
					px={['0.5rem', '16%']}
					bg="transparent"
					cursor="text"
					align="center"
					direction="column">
					<Textarea
						contentEditable="plaintext-only"
						suppressContentEditableWarning={true}
						ref={textAreaRef}
						data-gramm="true"
						data-enable-grammarly="true"
						onPaste={(e) => handleTextChange(e)}
						onInput={(e) => handleTextChange(e)}
						onKeyDown={(e) => handleTextChange(e)}
						onFocus={(e) => handleFocus(e)}
						onBlur={(e) => handleBlur(e)}
						defaultValue={value}
						rows={restricted ? currentLines : rows}
						cols={cols}
						_focus={{ border: 'none' }}
						textAlign="center"
						p="0"
						bg="transparent"
						resize="none"
						overflow="hidden"
						m="auto"
						lineHeight="2.5rem"
						fontSize="2rem"
						outline="none"
						border="none"
						color="gray.900"
						sx={{ caretColor: '#ff1c63' }}
						animation={overCharLimit ? `400ms ease-in-out 0s 1 ${warnRedText}` : ``}
					/>

					{charCount <= 0 && (
						<Box
							position="absolute"
							left="0"
							right="0"
							transform="translateY(-50%)"
							top="50%"
							textAlign="center"
							lineHeight="2.5rem"
							fontSize="2rem"
							color="gray.300"
							// left="50%"
							onClick={() => textAreaRef.current?.focus()}>
							{placeholder}
						</Box>
					)}

					<Text
						position="absolute"
						bottom="1rem"
						right="1rem"
						fontSize="1rem"
						letterSpacing="0.5px"
						fontWeight="600"
						color="#6C7A88">
						<strong>{charLimits}</strong>/{maxChars}
					</Text>
				</Flex>
			</Box>
		</Box>
	)
}

TextAreaForm.defaultProps = {
	/** Default placeholder */
	placeholder: 'Type your message here.',
	/** Maximum characters allowance */
	maxChars: 110,
	/** Should the textarea be focused */
	focused: false,
	/** # of colums */
	cols: 24,
	/** # of rows */
	rows: 6,
	/** Current lines */
	currentLines: 1,
	/** Default media object */
	// data: Schema('EventMedia').defaultProps,
}

TextAreaForm.propTypes = {
	/** Number of columns to display */
	cols: PropTypes.number,
	/** Number of rows to display */
	rows: PropTypes.number,
	/** Maximum amount of characters */
	maxChars: PropTypes.number,
	/** Placeholder text */
	placeholder: PropTypes.string,
	/** Should we focus on the input */
	focused: PropTypes.bool,
	/** Callback handler when the text value changes */
	onChange: PropTypes.func.isRequired,
	/** value of the text card form */
	// data: PropTypes.shape(Schema('EventMedia').propTypes),
}

export default TextAreaForm
