import { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateMedia } from '../../../../../api/app/media'
import { useMediaItemContext } from '../../../../contexts/MediaItemProvider'

const useForegroundEligible = (data) => {
	/** Retrieve dispatch method */
	const dispatch = useDispatch()

	/** Retrieve item from the context */
	const { item } = useMediaItemContext()

	/** Manage Mute Audio State */
	const [foregroundEligible, setForegroundEligible] = useState(item.foregroundEligible)

	useEffect(() => {
		setForegroundEligible(item.foregroundEligible)
	}, [item])

	/** Callback handler to update the mute audio */
	const handleForegroundEligible = useCallback(() => {
		let newData = {
			id: item.id,
			mediaUuid: item.mediaUuid,
		}
		newData.foregroundEligible = !foregroundEligible
		newData.socketReloadPage = true
		dispatch(updateMedia(newData, false))
		setForegroundEligible(!foregroundEligible)
	}, [foregroundEligible, item])

	return [foregroundEligible, handleForegroundEligible]
}

export default useForegroundEligible
