import { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

const useRenderJobsDetails = () => {
	const renderJobs = useSelector((state) => state.event.renderJobs, shallowEqual)

	let initialState = {
		previewCount: 0,
		previewCountInProgress: 0,
		finalCount: 0,
		finalCountInProgress: 0,
	}

	let [renderJobsDetails, setRenderJobsDetails] = useState(initialState)

	// Update OS when mounting component
	useEffect(() => {
		// Possible error states
		let errorStates = ['error', 'failed']

		// Possible complete / success states
		let successStates = ['finished']

		let previewCount = renderJobs?.filter((j) => {
			return j.preview == true && !errorStates.includes(j.jobState)
		})?.length

		let previewCountInProgress = renderJobs?.filter((j) => {
			return j.preview == true && !errorStates.includes(j.jobState) && !successStates.includes(j.jobState)
		}).length

		let finalRenderCount = renderJobs?.filter((j) => {
			return j.preview !== true && !errorStates.includes(j.jobState)
		})?.length

		let finalCountInProgress = renderJobs?.filter((j) => {
			return j.preview !== true && !errorStates.includes(j.jobState) && !successStates.includes(j.jobState)
		}).length

		let updatedStatus = {
			previewCount: previewCount,
			previewCountInProgress: previewCountInProgress,
			finalCount: finalRenderCount,
			finalCountInProgress: finalCountInProgress,
		}

		setRenderJobsDetails(updatedStatus)
	}, [renderJobs])

	return renderJobsDetails
}

export default useRenderJobsDetails
