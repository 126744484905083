import React, { useEffect, useState } from 'react'
import useRenderJobsDetails from '../../../hooks/useRenderJobsDetails'
import { CircularInput, CircularTrack, CircularProgress, CircularThumb } from 'react-circular-input'
import {
	Modal,
	VStack,
	Button,
	Heading,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Tooltip,
	Text,
} from '@chakra-ui/react'

export const ModalSetDuration = ({
	onClose,
	isOpen,
	duration,
	handleDurationChange,
	isNewTextCard,
	handleSetNewTextDuration,
}) => {
	const [currentDuration, setCurrentDuration] = useState(duration)
	const min = 0.0667
	const valueWithinMinLimit = (v) => Math.max(v, min)
	const renderJobsDetails = useRenderJobsDetails()
	const tip = "You can't edit the duration while a preview is being made"

	useEffect(() => {
		if (duration !== currentDuration) {
			setCurrentDuration(duration)
		}
	}, [duration])

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Heading size="lg" variant="hero" mb="1rem">
							Duration
						</Heading>

						<CircularInput
							style={{ cursor: 'pointer' }}
							value={valueWithinMinLimit(currentDuration / 60)}
							radius={69}
							onChange={(v) => setCurrentDuration(Math.round(valueWithinMinLimit(v) * 60))}>
							<CircularTrack strokeWidth={10} />
							<CircularProgress strokeWidth={16} />
							<g>
								<text
									style={{
										pointerEvents: 'none',
										fontWeight: 'bold',
										fontFamily: 'sans-serif',
										fontSize: 'xx-large',
									}}
									dx="50%"
									dy="57.5%"
									textAnchor="middle">
									{currentDuration}
								</text>
								<text
									style={{
										pointerEvents: 'none',
										fontFamily: 'sans-serif',
										fontSize: 'small',
										fill: 'var(--global-grayscale-3)',
									}}
									dx="50%"
									dy="67.5%"
									textAnchor="middle">
									sec.
								</text>
							</g>
							<CircularThumb r={4} />
						</CircularInput>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						{renderJobsDetails.previewCountInProgress > 0 ? (
							<Tooltip
								label={tip}
								aria-label={tip}
								hasArrow
								arrowSize={12}
								placement="top"
								fontSize="0.80rem">
								<Text cursor="pointer" display="flex" alignItems="center">
									<Button
										disabled={
											renderJobsDetails.previewCountInProgress > 0 || duration == currentDuration
										}
										onClick={() => {
											// onClose()
											isNewTextCard
												? handleSetNewTextDuration(currentDuration)
												: handleDurationChange(currentDuration)
											onClose()
										}}>
										Update
									</Button>
								</Text>
							</Tooltip>
						) : (
							<Button
								disabled={renderJobsDetails.previewCountInProgress > 0 || duration == currentDuration}
								onClick={() => {
									// onClose()
									isNewTextCard
										? handleSetNewTextDuration(currentDuration)
										: handleDurationChange(currentDuration)
									onClose()
								}}>
								Update
							</Button>
						)}
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
