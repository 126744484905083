import React, { useState, useEffect } from 'react'
import Controls from './Controls'
import { HStack, Container, Button } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import { useDispatch } from 'react-redux'
import Card from '../../atoms/Card'
import TextAreaForm from '../EditMedia/src/TextCard/TextAreaForm'
import Schema from '../../../../schemas'
import FooterBase from '../../molecules/FooterBase'
import { DurationSelectorButton } from '../EditMedia/buttons/DurationSelectorButton'
import { BackgroundMusicButtonUncontrolled } from '../EditMedia/buttons/BackgroundMusicButton'
import { DurationSelectorButtonUncontrolled } from '../EditMedia/buttons/DurationSelectorButton'
import randomBytes from 'randombytes'
import { postMedia } from '../../../../api/app/media'

const defaultSchema = Schema('EventMedia').defaultProps

const MediaActions = (props) => {
	return (
		<HStack spacing="0.5rem">
			<DurationSelectorButton />
			<BackgroundMusicButton />
		</HStack>
	)
}

const AddTextCard = () => {
	const { event } = useEventContext()
	const { uuid } = useParams()
	const dispatch = useDispatch()

	/** Retrieve history variable  */
	const navigate = useNavigate()

	/** Hold value of the text card */
	const [value, setValue] = useState('')

	/** Is the form save button disabled ? */
	const [saveDisabled, setSaveDisabled] = useState(true)

	/** Has changed values */
	const [hasChanged, setHasChanged] = useState(false)
	const [isMuted, setMuted] = useState(false)

	/** Hold duration value of the text card */
	const [duration, setDuration] = useState(4)

	useEffect(() => {
		if (value != '') {
			setHasChanged(true)
		} else setHasChanged(false)
		// if (value) setHasChanged(value != currentMedia.text)
	}, [value])

	/** Let's setup the default schema of a media */
	let currentMedia = defaultSchema
	/** Needed to let the media viewer know which kind of media this is */
	currentMedia.mimeType = 'text/plain'

	const handleFocus = () => {}
	const handleRedirectToEvent = () => navigate(`/event/${uuid}#addMedia`)

	/** Handler for when the value of the text card change */
	const handleValueChange = (media) => {
		/** Update the current value */
		setValue(media.text)
	}

	useEffect(() => {
		let text = currentMedia.text
		// const saveDisabled = value === textContent || textContent == text || textContent == '' ? true : false
		if (text == value || text == '' || value == '') {
			setSaveDisabled(true)
		} else setSaveDisabled(false)
	}, [currentMedia.text, value])

	const handleCancel = () => {
		setValue('')
		handleRedirectToEvent()
	}

	/** Handler for when the value text card is being saved */
	const handleSaveTextCard = (pro) => {
		// handleSaveTextCard
		/** Let's disable the save submit button on save */
		setSaveDisabled(true)
		/** Create a new media with new values (TODO : This could (and maybe should) actually be merged with the default media created) */
		let postData = {
			...currentMedia,
			eventId: event.id,
			text: value,
			mimeType: 'text/plain',
			key: null,
			sourceExt: null,
			mediaUuid: randomBytes(16).toString('hex'),
			duration: duration,
			muteBackgroundMusic: isMuted,
			backgroundEligible: false, // text shouldn't be in the background
		}

		/** Submit the new text card media */
		dispatch(postMedia(postData))

		/** Redirect to the event page */
		handleRedirectToEvent()
	}

	return (
		<>
			<Controls
				handleSave={handleSaveTextCard}
				handleBack={handleRedirectToEvent}
				handleCancel={handleCancel}
				hasChanged={hasChanged}
			/>

			<Container maxW="container.xl" pt={['0rem', '2rem']} pb="1rem" variant="main">
				<Card px={['1rem', '2rem']} py="2rem" alignItems="center" variant="main" mb="2rem">
					<TextAreaForm
						data={currentMedia}
						// dispatch={dispatch}
						// currentEvent={event}
						focused={false}
						onChange={handleValueChange}
						onFocus={handleFocus}
					/>

					<HStack mb="1rem">
						<DurationSelectorButtonUncontrolled duration={duration} setDuration={setDuration} />
						<BackgroundMusicButtonUncontrolled isMuted={isMuted} setMuted={() => setMuted(!isMuted)} />
					</HStack>

					<Button minW="100px" disabled={!hasChanged} onClick={handleSaveTextCard}>
						Save
					</Button>
				</Card>
			</Container>

			<FooterBase />
		</>
	)
}

export default AddTextCard
