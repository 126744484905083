import { useState, useEffect } from 'react'
import { useMediaItemContext } from '../../../../contexts/MediaItemProvider'

const useCollageBackground = () => {
	/** Retrieve item from the context */
	const { item, themeTemplateId } = useMediaItemContext()

	/** Manage Mute Audio State */
	const [hasCollageBackground, setHasCollageBackground] = useState(false)

	useEffect(() => {
		if (/^video/i.test(item.mimeType) || /^image/i.test(item.mimeType)) {
			if (themeTemplateId?.includes('collage')) {
				setHasCollageBackground(true)
			} else setHasCollageBackground(false)
		}
	}, [item, themeTemplateId])

	return hasCollageBackground
}

export default useCollageBackground
