import React from 'react'
import { Box, Text, Button } from '@chakra-ui/react'
import useMuteBackgroundMusic from '../hooks/useMuteBackgroundMusic'
import { MusicOffRounded, MusicNoteRounded } from '../../../atoms/Icon'

export const BackgroundMusicButton = () => {
	/** Retrieve state from context */
	const [muteBackgroundMusic, handleMuteBackgroundMusic] = useMuteBackgroundMusic()

	const selected = muteBackgroundMusic ? { colorScheme: 'red' } : {}

	return (
		<Box
			display="flex"
			alignItems="center"
			flexDirection="column"
			title={
				muteBackgroundMusic
					? 'Unmute background music during this clip.'
					: 'Mute background music during this clip.'
			}>
			<Button onClick={handleMuteBackgroundMusic} {...selected} mb="0.5rem" variant="mediaButton">
				{muteBackgroundMusic ? <MusicOffRounded color="link" /> : <MusicNoteRounded color="link" />}
			</Button>

			<Text variant="mediaButtonLabel">
				Background
				<br />
				Music {muteBackgroundMusic ? 'Off' : 'On'}
			</Text>
		</Box>
	)
}

export const BackgroundMusicButtonUncontrolled = ({ isMuted, setMuted }) => {
	const selected = isMuted ? { colorScheme: 'red' } : {}

	return (
		<Box
			display="flex"
			alignItems="center"
			flexDirection="column"
			title={isMuted ? 'Unmute background music during this clip.' : 'Mute background music during this clip.'}>
			<Button onClick={setMuted} {...selected} mb="0.5rem" variant="mediaButton">
				{isMuted ? <MusicOffRounded color="link" /> : <MusicNoteRounded color="link" />}
			</Button>

			<Text variant="mediaButtonLabel">
				Background
				<br />
				Music {isMuted ? 'Off' : 'On'}
			</Text>
		</Box>
	)
}
