import React from 'react'
import { Box, Text, Button, useDisclosure } from '@chakra-ui/react'
import { ModalSetDuration } from '../../../organisms/ModalSetDuration'
import useCollageBackground from '../hooks/useCollageBackground'
import useForegroundEligible from '../hooks/useForegroundEligible'
import { useLocation } from 'react-router-dom'
import useDuration from '../hooks/useDuration'

import { CircularInput, CircularTrack, CircularProgress, CircularThumb } from 'react-circular-input'

export const DurationSelectorButton = () => {
	const hasCollageBackground = useCollageBackground()
	const [foregroundEligible, handleForegroundEligible] = useForegroundEligible()
	const [duration, handleDurationChange] = useDuration()
	// const [isOpen, setIsOpen] = useState(false)
	const location = useLocation()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const newTextCard = location.pathname.includes('/card')

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				flexDirection="column"
				title={
					hasCollageBackground && !foregroundEligible
						? `Photo will NOT be displayed in foreground`
						: `Photo will be displayed for ${duration}sec.`
				}>
				<Button
					onClick={onOpen}
					disabled={hasCollageBackground && !foregroundEligible ? true : false}
					mb="0.5rem"
					variant="mediaButton">
					<CircularInput value={duration / 60} radius={29} style={{ maxWidth: '36px' }}>
						<CircularTrack strokeWidth={6} />
						<CircularProgress strokeWidth={10} />
						<text
							style={{
								pointerEvents: 'none',
								fontWeight: 'bold',
								fontFamily: 'sans-serif',
								fontSize: 'x-large',
							}}
							dx="50%"
							dy="65%"
							textAnchor="middle">
							{newTextCard ? newTextDuration : duration}
						</text>
						<CircularThumb r={4} />
					</CircularInput>
				</Button>

				<Text variant="mediaButtonLabel">
					Duration
					<br />
					{newTextCard ? newTextDuration : duration}sec
				</Text>
			</Box>
			<ModalSetDuration
				isOpen={isOpen}
				onClose={onClose}
				duration={duration}
				handleDurationChange={handleDurationChange}
			/>
		</>
	)
}

export const DurationSelectorButtonUncontrolled = ({ duration, setDuration }) => {
	const location = useLocation()

	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<Box display="flex" alignItems="center" flexDirection="column">
				<Button onClick={onOpen} disabled={false} mb="0.5rem" variant="mediaButton">
					<CircularInput value={duration / 60} radius={29} style={{ maxWidth: '36px' }}>
						<CircularTrack strokeWidth={6} />
						<CircularProgress strokeWidth={10} />
						<text
							style={{
								pointerEvents: 'none',
								fontWeight: 'bold',
								fontFamily: 'sans-serif',
								fontSize: 'x-large',
							}}
							dx="50%"
							dy="65%"
							textAnchor="middle">
							{duration}
						</text>
						<CircularThumb r={4} />
					</CircularInput>
				</Button>

				<Text variant="mediaButtonLabel">
					Duration
					<br />
					{duration}sec
				</Text>
			</Box>
			<ModalSetDuration
				isOpen={isOpen}
				onClose={onClose}
				duration={duration}
				handleDurationChange={setDuration}
			/>
		</>
	)
}
