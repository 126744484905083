import React from 'react'
import { HStack } from '@chakra-ui/react'
import CancelButton from '../../atoms/nav-controls/CancelButton'
import BackButton from '../../atoms/nav-controls/BackButton'
import SaveButton from '../../atoms/nav-controls/SaveButton'
import NavBar from '../../atoms/NavBar'

const ControlsTextCard = ({ hasChanged, handleCancel, handleSave, handleBack }) => {
	return (
		<>
			<NavBar>
				<HStack spacing={['4%', '1rem']}>
					{hasChanged ? <CancelButton onClick={handleCancel} /> : <BackButton onClick={handleBack} />}
				</HStack>
				<HStack spacing={['4%', '1rem']} justifyContent="flex-end">
					<SaveButton disabled={!hasChanged} onClick={handleSave} />
				</HStack>
			</NavBar>
		</>
	)
}

export default ControlsTextCard
